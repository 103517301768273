import styled from 'styled-components';

export const Container = styled.div`
	width: 38rem;
	height: 4.5rem;
	position: relative;
	display: inline-block;
	margin: 0 auto;
`;

export const SearchResults = styled.div``;
